body {
  background-color: rgb(230, 230, 230);
  font-family: Sans-serif;
}

ul {
  padding: 0;
}

input {
  height: 18px;
  cursor: text;
}

input::placeholder {
  user-select: text;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body.dark-theme {
  background-color: #1f1f1f;
  color: #cacaca;
}

/* ----- */
.topBar {
  background-color: rgb(220, 220, 220);
  position: fixed;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  box-shadow: 0px 5px 10px rgba(66, 66, 66, 0.34);
  font-size: 20px;
}

.topBar.dark-theme {
  background-color: #333333;
  box-shadow: 0px 5px 10px #30303057;
}

.userOptions {
  display: flex;
  gap: 20px;
}

.mainHeader {
  display: flex;
}
/* ----- */

.loginScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  background-color: rgb(215, 215, 215);
  border: 2px solid black;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 300px;
  padding: 30px;
  padding-top: 10px;
}

/* ----- */
.elementChoosen {
  padding-top: 80px;
  padding-left: 110px;
  padding-right: 110px;
}

.productInputTable {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 30px;
}

.productInput {
  width: 220px;
  gap: 3px;
}

.warehousePage {
  padding: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  border: 2px solid rgb(136, 136, 136);
  border-radius: 5px;
}

.WarehouseHeader {
  padding-bottom: 20px;
}

.warehouseInventory {
  background-color: rgb(215, 215, 215);
  flex-direction: row;
  height: 30px;
}

.warehouseInventoryShort {
  gap: 25px;
}

.productDescription {
  margin-bottom: 20px;
}

.confirmButton {
  margin-bottom: 10px;
  width: 100px;
  height: 25px;
}

.delete {
  background-color: rgba(255, 0, 0, 0.141);
}

/* ----- */

/* ----- */
.searchButton {
  width: 33px;
  height: 23px;
  font-size: 17px;
  justify-content: center;
}
/* ----- */

.searchPlan {
  padding-top: 15.75px;
  padding-bottom: 15.75px;
}

.newPlanDescrip {
  width: 495px;
  height: 150px;
  resize: none;
}

.newPlanTitle {
  width: 495px;
}

.planInfoEditDescrip {
  width: 300px;
  height: 200px;
  resize: none;
}

.planInformation {
  display: flex;
  flex-wrap: wrap;
}

.planInputTable {
  display: flex;
  gap: 50px;
}

/* ----- */
.topDashboard,
.midDashboard {
  display: flex;
}

.topDashboardBoxes {
  padding: 20px;
  padding-top: 5px;
}

.dashboardPlans {
  width: 400px;
}

.dashboardPlansInfo {
  width: 100px;
}

.inventoryPlans {
  padding-left: 50px;
  padding-right: 50px;
}

.inventortPlansWidth {
  width: 600px;
}

.inventoryPlansBoard {
  width: 80%;
  padding: 20px;
  padding-top: 5px;
}

.inventoryPlansIcon {
  font-size: 21px;
}

.warehouseDetail {
  width: 20%;
  min-height: 430px;
}

.circle {
  max-width: 200px;
  height: auto;
  border: 35px solid rgb(114, 114, 114);
  border-radius: 50%;
  justify-content: center;
  aspect-ratio: 1/1;
}
/* ----- */

.clientList {
  width: 350px;
  padding-top: 5px;
  padding-bottom: 20px;
}

.clientInfo {
  width: 990px;
  padding-top: 5px;
  padding-bottom: 20px;
}

.clientPurchaseList {
  background-color: rgb(215, 215, 215);
  padding-top: 10px;
  padding-bottom: 10px;
}

.clientPurchase {
  width: 100px;
}

.clientTypeOfLab {
  gap: 5px;
  padding-top: 20px;
}

.clientTypeOf {
  width: 150px;
  height: 22px;
}

.clientObs {
  width: 495px;
  height: 50px;
  resize: none;
}

.creatProductForm {
  background-color: rgb(215, 215, 215);
  border: 2px solid black;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 30px;
  padding-top: 10px;
}

.totalPurchSpend {
  color: black;
  border: none;
  background-color: rgb(230, 230, 230);
  font-size: 16px;
  font-weight: bold;
}

.creatCltPurch {
  background-color: rgb(215, 215, 215);
  border: 2px solid black;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%);
  width: 1300px;
  padding: 30px;
  padding-top: 10px;
}

.clientWareInventory {
  padding: 20px;
  padding-top: 0;
  overflow-y: auto;
  max-height: 200px;
}

.cltProdList {
  width: 49%;
}

.clientSearchInv {
  padding-bottom: 15.75px;
}

.clientInvPrice {
  width: 75px;
  text-align: right;
}

.clientSeeProd {
  background-color: rgb(215, 215, 215);
  border: 2px solid black;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  padding: 30px;
  padding-top: 10px;
}

/* ----- */
.creatSuppArr {
  background-color: rgb(215, 215, 215);
  border: 2px solid black;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%);
  width: 1400px;
  padding: 30px;
  padding-top: 10px;
}

.suppProdList {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  padding: 20px;
  max-width: 22%;
  overflow-y: auto;
  max-height: 800px;
}

.suppArrList {
  padding: 20px;
  width: 74%;
}

.suppArrListAdd {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 49%;
}

.suppArrListToAdd {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
}

.suppArrListAdded {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 93.5%;
}

.suppArrListSep {
  border-top: 3px solid black;
  padding-top: 10px;
}

.suppArrListSn {
  width: 1250px;
  height: 125px;
  margin-bottom: 5px;
}

.suppArrListFinish {
  font-weight: bold;
  width: 100px;
  height: 30px;
}

.suppArrListUnits {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
}

.suppSeeSN {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 10px;
}

.creatSuppProduct {
  background-color: rgb(215, 215, 215);
  border: 2px solid black;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  padding: 30px;
  padding-top: 10px;
}

.createProductSN {
  width: 500px;
  height: 60px;
}

.createProductPDF {
  width: 350px;
}

/* ------*/

.compoundInventory {
  padding: 10px;
  padding-left: 20px;
  max-height: 200px;
  overflow-y: auto;
}

.displayProducts {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}

.warehouseCompound {
  background-color: rgb(215, 215, 215);
  flex-direction: row;
  height: auto;
}

.compoundProducts {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/* ----- */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.485);
  z-index: 10;
  overflow: auto;
}

.alignCenter {
  display: flex;
  align-items: center;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.centerBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alignStart {
  display: flex;
  align-items: start;
}

.flexWrap {
  display: flex;
  flex-wrap: wrap;
}

.gap10 {
  gap: 10px;
}

.gap50 {
  gap: 50px;
}

.column {
  display: flex;
  flex-direction: column;
}

.thinBox {
  border: 1px solid rgb(136, 136, 136);
  border-radius: 3px;
}

.LRPadding20 {
  padding-left: 20px;
  padding-right: 20px;
}

.width100 {
  width: 100%;
}

.noMove {
  width: 174px;
}

.filter {
  height: 23.5px;
}

.larger {
  width: 227.5px;
}

.dates {
  text-align: center;
  width: 200px;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.greyDarker {
  background-color: #cccccc;
}

.marginTop {
  margin-top: 10px;
}

.teste {
  color: #ababab;
}

/* ----- */

/*
.bodyLayout {
  display: flex;
  border: 10px solid #8ec2de;
  border-radius: 20px;
  background-color: #80acbb58;
  min-height: 700px;
  margin: 20px;
  margin-top: 30px;
}

.leftBorder {
  display: flex;
  border-right: 5px solid #8ec2de;
  width: 175px;
  min-height: 700px;
}

.displayStock {
  display: flex;
}

.addProduct {
  display: flex;
  flex-direction: column;
  width: 400px;
}
*/
